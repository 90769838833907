<template>
  <div>
    <v-dialog
      max-width="400"
      v-model="dialog"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          class="ma-3"
          color="secondary"
          v-on="on"
        >
          Löschen
          <v-icon
            class="right"
            id="dropdown-icon"
            right
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          Vorlage löschen
        </v-card-title>

        <v-divider />

        <v-card-text class="pt-3">
          <!--          <v-alert-->
          <!--            class="mt-3"-->
          <!--            outlined-->
          <!--            text-->
          <!--            type="warning"-->
          <!--          >-->
          <!--            Achtung! Wenn die Konfiguration gelöscht wird, gilt an allen zugeordneten Abstellanlagen die Standardeinstellung, sofern vorhanden.-->
          <!--          </v-alert>-->

          Soll die Vorlage <span class="font-weight-bold">{{ this.getConfig(this.id).name }}</span> wirklich gelöscht werden?
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-spacer />

          <v-btn
            @click="cancel"
            color="secondary"
          >
            Abbrechen
          </v-btn>
          <v-btn
            @click="remove"
            color="primary"
          >
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	data() {
		return {
			dialog: false
		}
	},

	props: {
		id: {
			type: String,
			required: true
		}
	},

	computed: {
		...mapGetters({
			getConfig: 'placingConfiguration/getConfig'
		})
	},

	methods: {
		...mapActions({
			deleteConfigurationAction: 'placingConfiguration/deleteConfigurationAction'
		}),
		remove() {
			this.dialog = false
			this.deleteConfigurationAction(this.id)
		},
		cancel() {
			this.dialog = false
		}
	}
}
</script>
