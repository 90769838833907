<template>
  <v-container
    fluid
    class="height100"
  >
    <!--    series: {{this.getSeriesById(series)}}<br>-->
    <!--    series: {{series}}<br>-->
    <!--    location: {{location}}<br>-->
    <!--    location: {{this.getLocationById(location)}}<br>-->
    <!--    selected: {{this.getConfig(selected)}}<br>-->
    <!--    selected: {{selected}}<br>-->
    <!--    old selected: {{oldSelected}}<br>-->
    <!--    changed: {{changed}}<br>-->
    <!--    isDefaultConfigSelected: {{this.isDefaultConfigSelected()}}-->
    <!--    {{series}}<br>-->
    <!--    {{location}}<br>-->
    <!--    {{selected}}<br>-->
    <!--    {{changed}}<br>-->
    <!--    {{configForm}}<br>-->
    <!--    {{configForm.name === null}}<br>-->
    <!--    {{(selected || changed || configForm.name === null)}}-->
    <!--    all: {{this.getAllMappings}}-->
    <!--    {{emphasizedLocationNames}}-->
    <v-row class="height100">
      <v-col
        cols="2"
        class="height100"
      >
        <v-card class="height100">
          <v-card-text class="d-flex">
            <v-row>
              <v-col>
                <v-select
                  :items="this.getAllRegions"
                  @change="getRegionData"
                  item-text="name"
                  item-value="_id"
                  label="Region"
                  outlined
                  v-model="region"
                />
                <v-select
                  :items="this.seriesList"
                  @change="this.loadConfigOptions"
                  item-text="name"
                  item-value="_id"
                  label="Baureihe"
                  outlined
                  v-model="series"
                />
                <v-radio-group
                  @change="this.loadConfigOptions"
                  class="mt-0"
                  hide-details
                  label="Abstellanlagen"
                  v-if="region"
                  v-model="location"
                >
                  <v-radio
                    :key="option.name"
                    :label="`${option.name}`"
                    :value="option._id"
                    active-class="item_selected"
                    class="mb-0"
                    :class="{ emphasized: isEmphasized(option.name) }"
                    v-for="option in locations"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="10"
        class="height100"
      >
        <v-card class="height100">
          <v-card-text>
            <v-row>
              <v-col
                class="pt-0"
                cols="8"
              >
                <v-card-title
                  class="title pt-0 cardTitleColor"
                  v-if="series && location"
                >
                  {{ labelForConfigurationSelect }}
                </v-card-title>
              </v-col>
              <v-col
                class="pt-0"
                cols="4"
              >
                <v-select
                  :items="configOptions"
                  @change="this.selectionChanged"
                  hide-details
                  item-text="name"
                  item-value="_id"
                  label="Vorlage"
                  outlined
                  v-if="series && location"
                  v-model="selected"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <edit-config
                  :config="configForm"
                  v-if="series && location"
                />
              </v-col>
              <!--                  v-if="series && location && (selected || changed || configForm !== null)"-->
            </v-row>
            <v-row :justify="'end'">
              <v-btn
                @click="cancel"
                class="ma-3"
                color="secondary"
                v-if="changed"
              >
                Abbrechen
              </v-btn>
              <v-btn
                @click="save"
                class="ma-3"
                color="primary"
                v-if="changed"
              >
                Speichern
              </v-btn>
            </v-row>
            <v-spacer />
            <v-row />
            <v-row />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import EditConfig from './edit-config'
//import AddConfigDialog from './add-dialog'

export default {
	name: 'PlacingConfiguration',

	components: {
	  EditConfig,
	  //AddConfigDialog,
	  //EditConfigDialog,
	  //ConfirmDeleteDialog
	},

	computed: {
		...mapGetters({
			getAllLocationOptions: 'location/getAllLocationOptions',
			getLocationById: 'location/getLocationById',
			getMapping: 'mapping/getMapping',
			getDefaultMapping: 'mapping/getDefaultMapping',
			getAllMappings: 'mapping/getAllMappings',
		getMappingUpdated: 'mapping/getMappingUpdated',
			getConfig: 'placingConfiguration/getConfig',
		getConfigurationUpdated: 'placingConfiguration/getConfigurationUpdated',
			getConfigOptions: 'placingConfiguration/getConfigOptions',
		getConfigOptionsRegion: 'placingConfiguration/getConfigOptionsRegion',
		getFormChanged: 'placingConfiguration/getFormChanged',
			getAllSeriesOptions: 'series/getAllSeriesOptions',
		getLoginUser: 'login/getLoginUser',
		getSeriesById: 'series/getSeriesById',
		getAllRegions: 'region/getAllRegions',
		getRegion: 'region/getRegion',
		getSeriesOfRegion: 'region/getSeriesOfRegion',
		getLocationsOfRegion: 'region/getLocationsOfRegion',
		getLocationsOfLocalMappings: 'mapping/getLocationsOfLocalMappings',
		getUserRegion: 'user/getUserRegion'
		}),
	  ...mapState({
		  getFormChanged: 'formChanged'
	  }),
		labelForConfigurationSelect() {
			const series = this.getSeriesById(this.series)
			const location = this.getLocationById(this.location)
			return 'Einstellung für die Baureihe ' + series.name + ' an der Abstellanlage ' + location.name
		},
	},

	data() {
		return {
			series: null,
			location: null,
			configOptions: [],
			selected: null,
			oldSelected: null,
		changed: false,
		region: null,
		locations: [],
		seriesList: [],
		emphasizedLocationNames: [],
		configForm: {
			region: {},
			series: {},
			preHeatUnit: null,
			preHeatTrain: null,
			particularities: null,
			additionalInformation: null
		}
		}
	},

	methods: {
		...mapActions({
			getAllConfigurationsAction: 'placingConfiguration/getAllConfigurationsAction',
		resetConfigurationUpdatedAction: 'placingConfiguration/resetConfigurationUpdatedAction',
			getAllLocationsAction: 'location/getAllLocationsAction',
			getAllSeriesAction: 'series/getAllSeriesAction',
		getAllMappingsAction: 'mapping/getAllMappingsAction',
		saveMappingAction: 'mapping/saveMappingAction',
		deleteMappingAction: 'mapping/deleteMappingAction',
		resetMappingUpdatedAction: 'mapping/resetMappingUpdatedAction',
		getAllRegionsAction: 'region/getAllRegionsAction',
		resetFormChangedAction: 'placingConfiguration/resetFormChangedAction'
		}),
		loadConfigOptions() {
		this.selectFirstLocationIfEmpty()
      //const defaultMapping = this.getDefaultMapping(this.series)
		this.configOptions = this.getConfigOptionsRegion(this.series, this.region)
			this.loadMapping()
		//this.configOptions = options.map(option => {
		//	if (defaultMapping && option._id === defaultMapping.configuration._id) {
		//		let defaultOption = { ...option }
		//		defaultOption.name = option.name + ' (Standardeinstellung)'
		//		return defaultOption
		//	}
		//	return option
		//})
			this.changed = false
		//this.selectionChanged()
		this.getEmphasizedLocationNames()
	},
	  isEmphasized(name) {
		  return this.emphasizedLocationNames.includes(name)
	  },
		loadMapping() {
		if (this.region && this.series) {
			const locationMapping = this.getMapping(this.region, this.series, this.location)
			if (locationMapping) {
				if (locationMapping.template && this.configOptions.length > 0) {
					const selectedConfigIndex = this.configOptions.findIndex(item => item._id === locationMapping.template._id)
					this.selected = this.configOptions[selectedConfigIndex]._id
					this.configForm = { ...this.getConfig(this.selected) }
				} else {
					this.selected = null
					this.configForm = locationMapping.configuration
				}
				} else {
				const defaultMapping = this.getDefaultMapping(this.region, this.series)
				if (defaultMapping && this.configOptions.length > 0) {
					const selectedConfigIndex = this.configOptions.findIndex(item => item._id === defaultMapping.template._id)
					this.selected = this.configOptions[selectedConfigIndex]._id
					this.configForm = { ...this.getConfig(this.selected) }
				} else { // no config found
					this.selected = null
					this.configForm = {
						region: { _id: this.region },
						series: { _id: this.series },
						preHeatUnit: null,
						preHeatTrain: null,
						particularities: null,
						additionalInformation: null
					}
				}

				}
			}
		},
		selectionChanged() {
			if (this.oldSelected === this.selected) {
				this.changed = false
			} else {
				this.changed = true
			}
		let config = { ...this.getConfig(this.selected) }
		this.configForm = config
		},
		cancel() {
		this.resetFormChangedAction()
			this.selected = this.oldSelected
		this.configForm = { ...this.getConfig(this.selected) }
			this.changed = false
		this.loadMapping()
		},
		save() {
		if (this.selected) {
			this.saveMappingAction(
				{ region: this.region, series: this.series, location: this.location, template: this.selected, configuration: null })
		} else {
			const toSave = {
				preHeatUnit: this.configForm.preHeatUnit,
				preHeatTrain: this.configForm.preHeatTrain,
				particularities: this.configForm.particularities,
				additionalInformation: this.configForm.additionalInformation,
				series: this.configForm.series._id
			}
			this.saveMappingAction(
				{ region: this.region, series: this.series, location: this.location, template: null, configuration: toSave })
		}
			this.changed = false
	},
	  saveDefault() {
		  const mapping = this.getMapping(this.series, this.location)
		  if (mapping) {
			  this.deleteMappingAction(mapping._id)
		  }
		  this.saveMappingAction({ series: this.series, location: null, configuration: this.selected })
	  },
	  selectFirstLocationIfEmpty() {
		  if (!this.location && this.locations && this.locations.length > 0) {
			  this.location = this.locations[0]._id
		  }
	  },
	  selectFirstSeriesIfEmpty() {
		  if (!this.series && this.seriesList && this.seriesList.length > 0) {
			  this.series = this.seriesList[0]._id
		  }
	  },
	  getRegionData() {
		  this.changed = false

		  if (this.region) {
			  let region = this.getRegion(this.region)
			  //this.locations = region.locations.sort((a, b) => a.name.localeCompare(b.name))
			  this.locations = this.getLocationsOfRegion(this.region) || []
			  //this.seriesList = region.series.sort((a, b) => a.name.localeCompare(b.name))
			  this.seriesList = this.getSeriesOfRegion(this.region) || []
			  this.selectFirstLocationIfEmpty()
			  this.selectFirstSeriesIfEmpty()
			  this.configForm.region._id = this.region
			  this.configForm.series._id = this.series
			  this.getEmphasizedLocationNames()
		  } else {
			  this.locations = []
			  this.seriesList = []
			  this.emphasizedLocationNames = []
		  }
	  },
	  setDefaultRegion() {
		  if (this.getLoginUser.region) {
			  this.region = this.getLoginUser.region._id
			  this.getRegionData()
		  }
		  this.loadConfigOptions()
	  },
	  getEmphasizedLocationNames() {
		  this.emphasizedLocationNames = this.getLocationsOfLocalMappings(this.region, this.series)
	  },
	},

	watch: {
	  getMappingUpdated: function(val) {
		  if (val) {
			  this.loadConfigOptions()
			  this.resetMappingUpdatedAction()
		  }
	  },
	  getConfigurationUpdated: function(val) {
		  if (val) {
			  this.loadConfigOptions()
			  this.resetConfigurationUpdatedAction()
		  }
	  },
	  getFormChanged: function(newVal, oldVal) {
		  this.changed = newVal
		  this.selected = null
	  }
	},

	created() {
		this.getAllRegionsAction()
		this.getAllSeriesAction()
		this.getAllLocationsAction()
		this.getAllConfigurationsAction()
		this.getAllMappingsAction()
		this.resetFormChangedAction()
		this.changed = false
		this.setDefaultRegion()
		this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'placingConfiguration/formChangedMutation') {
				this.changed = state.placingConfiguration.formChanged = mutation.payload
		  if (this.changed === true) {
			  this.selected = null
		  }
	  }
		if (mutation.type === 'region/setAllRegionsMutation') {
			this.locations = this.getLocationsOfRegion(this.region)
			this.seriesList = this.getSeriesOfRegion(this.region)
			this.loadConfigOptions()
		}
		if (mutation.type === 'mapping/updateMappingMutation') {
			this.getEmphasizedLocationNames()
		}
		})
		let userRegion = this.getUserRegion(this.getLoginUser.id)
		this.region = userRegion ? userRegion._id : null
	}
}
</script>

<style scoped>
  .emphasized {
    font-weight: bolder;
  }

  .height100 {
    height: 100%;
  }

  .cardTitleColor {
    color: rgba(0, 0, 0, 0.87);
  }
</style>
