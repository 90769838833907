<template>
  <v-container>
    <v-row>
      <v-col />
      <v-col>
        <v-card elevation="12">
          <v-card-text>
            <v-alert
              :value="this.getLoginError !== null"
              color="error"
            >
              {{ getLoginError }}
            </v-alert>
            <v-form
              @submit.prevent="login"
              ref="form"
            >
              <v-text-field
                label="Benutzername"
                name="login"
                required
                type="text"
                v-model="username"
              />
              <v-text-field
                label="Passwort"
                name="password"
                required
                type="password"
                v-model="password"
              />
              <v-card
                class="d-flex flex-row-reverse"
                flat
              >
                <v-btn
                  class="d-flex"
                  color="primary"
                  large
                  type="submit"
                >
                  Einloggen
                </v-btn>
              </v-card>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col />
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'Login',

	data() {
		return {
			username: '',
			password: ''
		}
	},

	computed: {
		...mapGetters({
			getLoginError: 'login/getLoginError'
		})
	},

	mounted() {
	  document.title = 'ABSTELLUNG (DB REGIO)'
	},

	methods: {
	  ...mapActions({
		  loginAction: 'login/loginAction'
	  }),

		login() {
			const payload = {
				username: this.username,
				password: this.password
			}
			this.loginAction(payload)
		}
	}

}
</script>

<style lang="scss" scoped>
  #login:before {
    content: '';
    width: 100vw;
    height: calc(50vh - 65px);
    background: #ffd54f;
    position: absolute;
    top: -65px;
    left: 0;
  }

  #card-login {
    margin-top: -160px;
  }

  .v-application .primary {
    background-color: #1976d2 !important;
  }
</style>
