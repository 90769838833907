import axios from 'axios'
import router from '../router'
import { store } from '../store'
import { authorizationHeaderName } from '@/store/http-client'

const state = {
  loggedIn: false,
  user: null,
  error: null
}

// ACTIONS
const loginAction = async (context, payload) => {
  await axios.post('api/login', payload).catch(err => console.log('login error', err))
  await axios.get('api/user/findByUsername/' + payload.username).then(response => {
    context.commit('loginUserMutation', response.data)
    store.commit('user/updateUserMutation', response.data)
    router.push('/placing-configurations')
  }).catch(error => {
    console.log('login error', error)
    if (error.response.status === 401) {
      state.error = 'Benutzername oder Passwort falsch'
    } else {
      state.error = 'Login nicht möglich'
    }
  })
}

const getLoginUserAction = async context => {
  if (localStorage.getItem('username') !== null) {
    const response = await axios.get('api/user/findByUsername/' + localStorage.getItem('username'))
      .then(response => context.commit('loginUserMutation', response.data))
      .catch(error => console.log('login error', error))
  }
}

const logoutAction = async (context) => {
  await axios.get('api/logout').then(response => {
    context.commit('logoutUserMutation')
    router.push('api/login')
      .catch(error => console.log('error', error))
  }).catch(error => console.log('error', error))
}

// MUTATIONS
const loginUserMutation = (state, user) => {
  state.user = user
  state.loggedIn = true
  state.error = null
  localStorage.setItem('username', user.username)
}

const logoutUserMutation = state => {
  state.loggedIn = false
  state.user = null
  localStorage.removeItem(authorizationHeaderName)
  localStorage.removeItem('username')
}

const setLoginErrorMutation = (state, message) => {
  state.loggedIn = false
  state.error = message
}

const actions = {
  loginAction,
  logoutAction,
  getLoginUserAction
}

const mutations = {
  loginUserMutation,
  logoutUserMutation,
  setLoginErrorMutation
}

const getters = {
  getLoginUser: state => state.user,
  getLoginError: state => state.error,
  isLoggedIn: state => state.loggedIn,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
