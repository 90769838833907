import axios from 'axios'

const state = {
  allLocations: []
}

const getAllLocationsAction = async context => {
  await axios.get('api/location')
    .then(response => context.commit('setAllLocationsMutation', response.data))
    .catch(error => console.log('location error', error))
}

const saveLocationAction = async (context, payload) => {
    await axios.post('api/location/save', payload)
      .then(response => context.commit('setLocationMutation', response.data))
      .catch(error => console.log('error', error))
}

const actions = {
  getAllLocationsAction,
  saveLocationAction
}

const setAllLocationsMutation = (state, allLocations) => {
  state.allLocations = allLocations
}

const setLocationMutation = (state, location) => {
  state.allLocations.push(location)
}

const mutations = {
  setAllLocationsMutation,
  setLocationMutation
}

const getters = {
  getAllLocations: state => state.allLocations.sort((a, b) => a.name.localeCompare(b.name)),
  getAllLocationOptions: state => state.allLocations.map(location => {
    return { _id: location._id, name: location.name }
  }),
  getLocationById: state => id => state.allLocations.find(location => location._id === id)
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
