<template>
  <div>
    <div
      :key="item._id"
      v-for="item in items"
    >
      <div
        class="mt-1 minHeight24"
      >
        <label
          class="v-label item_emphasized"
          v-if="emphasizedItems && emphasizedItems.length> 0 && emphasizedItems.indexOf(item.name) > -1"
        >
          {{ item.name }}
        </label>
        <label
          class="v-label"
          v-else
        >{{ item.name }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	name: "ViewLocationList",
	props: {
		items: {
			type: Array,
			required: true
	},
	  emphasizedItems: {
		  type: Array,
		  required: false,
		  default: () => []
	  }
	}
}
</script>
<style scoped>
  .item_emphasized {
    font-weight: bolder;
  }

  .minHeight24 {
    min-height: 24px;
  }
</style>
