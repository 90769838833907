<template>
  <v-container
    fluid
    class="height100"
  >
    <v-row class="height100">
      <v-col
        cols="2"
        class="height100"
      >
        <v-card class="height100">
          <v-card-text class="d-flex">
            <v-row>
              <v-col>
                <v-select
                  :items="this.getAllRegions"
                  @change="getRegionData"
                  item-text="name"
                  item-value="_id"
                  label="Region"
                  outlined
                  v-model="region"
                />
                <v-select
                  :items="this.seriesList"
                  @change="this.loadConfigOptions"
                  item-text="name"
                  item-value="_id"
                  label="Baureihe"
                  outlined
                  v-model="series"
                />
                <ViewLocationList
                  :items="this.getRegion(this.region).locations"
                  :emphasized-items="this.emphasizedLocationNames"
                  v-if="region"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="10"
        class="height100"
      >
        <v-card class="height100">
          <v-card-title class="title">
            Vorlagen
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-select
                  :items="configOptions"
                  hide-details
                  item-text="name"
                  item-value="_id"
                  outlined
                  v-if="series && region"
                  v-model="selectedConfig"
                />
              </v-col>
            </v-row>

            <v-row :justify="'end'">
              <v-btn
                @click="saveDefault"
                class="ma-3"
                v-if="selectedConfig && series"
              >
                Als Standardeinstellung für Baureihe
                {{ this.getSeriesById(this.series).name }}
                festlegen
              </v-btn>
              <add-config-dialog
                :region="region"
                :series="series"
                v-if="series && region && !changed"
              />
              <edit-config-dialog
                :config="this.getSelectedConfig()"
                v-if="series && region && !changed && selectedConfig"
              />
              <confirm-delete-dialog
                :id="selectedConfig"
                v-if="series && region && selectedConfig && !changed"
              />
            </v-row>

            <v-row>
              <v-col>
                <view-config
                  :config="this.getConfig(selectedConfig)"
                  v-if="series && region && selectedConfig"
                />
              </v-col>
            </v-row>
            <v-spacer />
            <v-row />
            <v-row />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ViewConfig from '../placing-configuration/view-config'
import AddConfigDialog from '../placing-configuration/add-dialog'
import EditConfigDialog from '../placing-configuration/edit-dialog'
import ConfirmDeleteDialog from '../placing-configuration/confirm_delete_dialog'
import ViewLocationList from '../common/view-item-list'

export default {
	name: "Templates",
	components: {
		ViewConfig,
		AddConfigDialog,
		EditConfigDialog,
		ConfirmDeleteDialog,
		ViewLocationList
	},
	data() {
		return {
		region: null,
		locations: [],
		location: null,
		series: null,
		seriesList: [],
		configOptions: [],
		selectedConfig: null,
		changed: false,
		emphasizedLocationNames: []
		}
	},
	computed: {
		...mapGetters({
		getAllRegions: 'region/getAllRegions',
		getAllSeriesOptions: 'series/getAllSeriesOptions',
		getRegion: 'region/getRegion',
		getConfigOptionsRegion: 'placingConfiguration/getConfigOptionsRegion',
		getConfig: 'placingConfiguration/getConfig',
		getSeriesById: 'series/getSeriesById',
		getConfigurationUpdated: 'placingConfiguration/getConfigurationUpdated',
		getDefaultMapping: 'mapping/getDefaultMapping',
		getLocationsOfLocalMappings: 'mapping/getLocationsOfLocalMappings',
		getLoginUser: 'login/getLoginUser',
		getMappingUpdated: 'mapping/getMappingUpdated'
		})
	},

	methods: {
		...mapActions({
		getAllRegionsAction: 'region/getAllRegionsAction',
		resetConfigurationUpdatedAction: 'placingConfiguration/resetConfigurationUpdatedAction',
		saveDefaultAction: 'mapping/saveDefaultAction',
		getAllMappingsAction: 'mapping/getAllMappingsAction',
		resetMappingUpdatedAction: 'mapping/resetMappingUpdatedAction'
	}),
	  getRegionData() {
		  if (this.region) {
			  let region = this.getRegion(this.region)
			  this.locations = region.locations.sort((a, b) => a.name.localeCompare(b.name))
			  this.seriesList = region.series.sort((a, b) => a.name.localeCompare(b.name))
		  } else {
			  this.locations = []
			  this.seriesList = []
		  }
	  },
	  getEmphasizedLocationNames() {
		  this.emphasizedLocationNames = this.getLocationsOfLocalMappings(this.region, this.series)
	  },
	  loadConfigOptions() {
		  this.selectedConfig = null
		  this.configOptions = this.getConfigOptionsRegion(this.series, this.region)
		  if (!this.selectedConfig && this.configOptions.length > 0) {
			  const defaultMapping = this.getDefaultMapping(this.region, this.series)
			  if (defaultMapping) {
				  const selectedConfigIndex = this.configOptions.findIndex(item => item._id === defaultMapping.template._id)
				  this.selectedConfig = this.configOptions[selectedConfigIndex]._id
			  }
		  }
		  this.getEmphasizedLocationNames()
	  },
	  saveDefault() {
		  this.saveDefaultAction({ regionId: this.region, seriesId: this.series, templateId: this.selectedConfig })
	  },
	  setDefaultRegion() {
		  if (this.getLoginUser.region) {
			  this.region = this.getLoginUser.region._id
			  this.getRegionData()
		  }
	  },
	  getSelectedConfig() {
		  return this.getConfig(this.selectedConfig)
	  }
  },

	watch: {
		getConfigurationUpdated: function(val) {
			if (val) {
				this.configOptions.push(val)
				this.selectedConfig = val._id
				this.resetConfigurationUpdatedAction()
			}
	},
	  getMappingUpdated: function(val) {
		  if (val) {
			  this.getEmphasizedLocationNames()
			  this.resetMappingUpdatedAction()
		  }
	  }

	},

	mounted() {
		this.getAllRegionsAction()
	  this.getAllMappingsAction()
	  this.setDefaultRegion()
	}
}
</script>

<style scoped>
  .item_selected {
    font-weight: bolder;
  }

  .height100 {
    height: 100%;
  }
</style>
