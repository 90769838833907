<template>
  <v-row>
    <v-col class="pa-0">
      <span
        class="ml-3"
        v-if="this.isLoggedIn && this.getLoginUser"
      >
        <v-icon>mdi-account</v-icon>
        {{ getLoginUser.lastname }}, {{ getLoginUser.firstname }}
      </span>
      <span
        class="ml-3"
        v-else
      >Nicht angemeldet</span>
    </v-col>
    <v-col class="pa-0">
      <div class="mr-3 text-right" >
        {{ getSystemInfo.app.version }} / {{ getSystemInfo.app.profile }}
        <!--                <span v-if="getSystemInfo.app.profile !== 'pu'">({{ getSystemInfo.git.commit.id }})</span>-->
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'SystemInfo',
	computed: {
		...mapGetters({
		getSystemInfo: 'systemInfo/getSystemInfo',
		getLoginUser: 'login/getLoginUser',
		isLoggedIn: 'login/isLoggedIn'
		})
  },
	methods: {
		...mapActions({
			getLoginUserAction: 'login/getLoginUserAction'
		})
	},
	mounted() {
		this.getLoginUserAction()
	}
}
</script>
