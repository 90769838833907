import Vue from 'vue'
import Router from 'vue-router'
import Login from '../components/login'
import PlacingConfiguration from '../components/placing-configuration'
import ManageUsers from '../components/manage-users'
import Templates from '../components/templates/index'
import Region from '../components/region/index'
import { store } from '@/store'
import { authorizationHeaderName } from '@/store/http-client'

Vue.use(Router)

const basicRoutes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
      title: 'Login'
    }
  },
  {
    path: '/placing-configurations',
    name: 'placing-configurations',
    component: PlacingConfiguration,
    meta: {
      requiresAuth: true,
      title: 'Abstell-Szenarien'
    }
  },
  {
    path: '/templates',
    name: 'templates',
    component: Templates,
    meta: {
      requiresAuth: true,
      title: 'Vorlagen'
    }
  },
  {
    path: '/region',
    name: 'region',
    component: Region,
    meta: {
      requiresAuth: true,
      title: 'Regionen'
    }
  },
  {
    path: '/admin/manage-users',
    name: 'manage-users',
    component: ManageUsers,
    meta: {
      requiresAuth: true,
      title: 'Benutzerverwaltung'
    }
  },
  {
    path: '*',
    redirect: '/login'
  }
]

const routes = [
  ...basicRoutes
]

const router = new Router({ routes })

const isLoggedIn = () => {
  if (store.state.login.loggedIn) {
    return true
  }
  if (localStorage.getItem(authorizationHeaderName)) {
    store.state.login.loggedIn = true
    return true
  }
  return false
}

export const routerGuard = (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isLoggedIn()) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
      return
    } else {
      next()
      return
    }
  }
  next()
}

router.beforeEach(routerGuard)

export default router
