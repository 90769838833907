<template>
  <v-app>
    <v-system-bar>
      <!-- -->
    </v-system-bar>
    <Menu />

    <v-main>
      <!-- If using vue-router -->
      <router-view><!-- --></router-view>
    </v-main>

    <v-footer
      app
      color="grey lighten-2"
      fixed
    >
      <system-info />
    </v-footer>
  </v-app>
</template>

<script>
import Menu from './components/menu'
import SystemInfo from './components/system-info'
import { mapActions } from 'vuex'

export default {
	name: 'App',
	components: { Menu, SystemInfo },
	data: () => ({
		//
	}),
	methods: {
		...mapActions({
			getSystemInfoAction: 'systemInfo/getSystemInfoAction'
		})
	},
	mounted() {
		this.getSystemInfoAction()
	}
};
</script>
