<template>
  <v-card>
    <v-divider />
    <!--        {{config}}<br>-->
    <!--    {{configuration}}-->
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-row>
          <v-col
            cols="6"
            v-if="config.series && hasPreHeatUnitOptions()"
          >
            <v-radio-group
              @change="changed"
              class="mt-0"
              hide-details
              label="Abstellen in"
              v-if="config.series"
              v-model="config.preHeatUnit"
            >
              <v-radio
                :key="option"
                :label="`${option}`"
                :value="option"
                class="mb-0"
                v-for="option in this.getPreHeatUnitOptions"
              />
            </v-radio-group>

            <v-btn
              @click="resetPreHeatUnit"
              class="mt-3"
              v-if="config.series"
            >
              Zurücksetzen
            </v-btn>
          </v-col>
          <v-col
            cols="6"
            v-if="config.series && hasPreHeatTrainOptions()"
          >
            <v-radio-group
              @change="changed"
              class="mt-0"
              hide-details
              label="Vorheizen mit Tfz"
              v-model="config.preHeatTrain"
            >
              <v-radio
                :key="option"
                :label="`${option}`"
                :value="option"
                class="mb-0"
                v-for="option in this.getPreHeatTrainOptions"
              />
            </v-radio-group>
            <v-btn
              @click="resetPreHeatTrain"
              class="mt-3"
              v-if="config.series"
            >
              Zurücksetzen
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="pl-0"
          >
            <v-textarea
              :label="`Besonderheiten`"
              outlined
              v-if="config.series"
              v-model="config.particularities"
              @change="changed"
              @input="changed"
            />
          </v-col>
          <v-col
            class="pr-0"
          >
            <v-textarea
              :label="`Zusatzinformationen`"
              outlined
              v-if="config.series"
              v-model="config.additionalInformation"
              @change="changed"
              @input="changed"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-divider />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	data() {
		return {
			dialog: false,
			configuration: {},
			valid: true,
			nameRules:
				[
					v => !!v || 'Es muss ein Name vergeben werden'
				]
		}
	},

	props: {
		config: {
			type: Object,
		required: true,
		}
	},

	computed: {
		...mapGetters({
			getAllSeries: 'series/getAllSeries',
			getSeriesById: 'series/getSeriesById'
		}),
		getSeriesOptions() {
			return this.getAllSeries.map(series => {
				return { '_id': series._id, 'name': series.name }
			})
		},
		getPreHeatUnitOptions() {
			return this.getAllSeries.find(series => series._id === this.config.series._id).preHeatUnitOptions
		},
		getPreHeatTrainOptions() {
			return this.getAllSeries.find(series => series._id === this.config.series._id).preHeatTrainOptions
		},
	},

	methods: {
		...mapActions({
			setFormChangedAction: 'placingConfiguration/setFormChangedAction',
			resetFormChangedAction: 'placingConfiguration/resetFormChangedAction'
		}),
	  hasPreHeatTrainOptions() {
		  let preHeatTrainOptions = this.getAllSeries.find(series => series._id === this.config.series._id).preHeatTrainOptions
		  return preHeatTrainOptions !== null && preHeatTrainOptions.length > 0
		},
	  hasPreHeatUnitOptions() {
		  let preHeatUnitOptions = this.getAllSeries.find(series => series._id === this.config.series._id).preHeatUnitOptions
		  return preHeatUnitOptions !== null && preHeatUnitOptions.length > 0
		},
		resetPreHeatUnit() {
		this.config.preHeatUnit = null
		},
		resetPreHeatTrain() {
		this.config.preHeatTrain = null
		},
		changed() {
			const props = Object.getOwnPropertyNames(this.config);
		let changed = false
			for (let i = 0; i < props.length; i++) {
				let propName = props[i];
		  if (propName !== '__ob__' && this.isDifferent(this.config[propName], this.configuration[propName])) {
					this.setFormChangedAction()
			  changed = true
				}
			}
		if (!changed) {
			this.resetFormChangedAction()
		}
	},
	  isDifferent(val1, val2) {
		  if ((val1 === null && val2 === '') || val2 === null && val1 === '') {
			  return false
		  }
		  return val1 !== val2
	  }
	},

	watch: {
		dialog: function(val) {
			if (val === false) {
				this.configuration = {
					_id: null,
					active: false,
					additionalInformation: null,
					particularities: null,
					preHeatTrain: null,
					preHeatUnit: null,
					series: null,
					name: null
				}
			}
		}
	},

	mounted() {
		this.resetFormChangedAction()
	}

}
</script>
