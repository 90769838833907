<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="1200"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="ma-3"
        >
          Neu
          <v-icon
            class="right"
            id="dropdown-icon"
            right
          >
            mdi-plus-box
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          Neue Vorlage erstellen
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-row>
              <v-text-field
                v-model="config.name"
                label="Name"
                placeholder="neue Konfiguration"
                autofocus
                class="pt-3"
                outlined
                required
                :rules="nameRules"
              />
            </v-row>
            <v-row>
              <v-select
                :items="this.getSeriesOptions"
                label="Baureihe"
                outlined
                hide-details
                item-text="name"
                item-value="_id"
                disabled
                v-model="config.series"
              />
            </v-row>
            <v-row>
              <v-col
                cols="6"
                v-if="config.series && hasPreHeatUnitOptions(config.series)"
              >
                <v-radio-group
                  label="Abstellmodus"
                  class="mt-0"
                  hide-details
                  v-model="config.preHeatUnit"
                  v-if="config.series"
                >
                  <v-radio
                    :key="option"
                    :label="`${option}`"
                    :value="option"
                    class="mb-0"
                    v-for="option in this.getPreHeatUnitOptions"
                  />
                </v-radio-group>

                <v-btn
                  @click="resetPreHeatUnit"
                  class="mt-3"
                  v-if="config.series"
                >
                  Zurücksetzen
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                v-if="config.series && hasPreHeatTrainOptions(config.series)"
              >
                <v-radio-group
                  label=""
                  class="mt-0"
                  hide-details
                  v-model="config.preHeatTrain"
                >
                  <v-radio
                    :key="option"
                    :label="`${option}`"
                    :value="option"
                    class="mb-0"
                    v-for="option in this.getPreHeatTrainOptions"
                  />
                </v-radio-group>

                <v-btn
                  @click="resetPreHeatTrain"
                  class="mt-3"
                  v-if="config.series"
                >
                  Zurücksetzen
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  :label="`Besonderheiten`"
                  outlined
                  v-model="config.particularities"
                  v-if="config.series"
                />
              </v-col>
              <v-col>
                <v-textarea
                  :label="`Zusatzinformationen`"
                  outlined
                  v-model="config.additionalInformation"
                  v-if="config.series"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-spacer />

          <v-btn
            @click="cancel"
            color="secondary"
          >
            Abbrechen
          </v-btn>
          <v-btn
            :disabled="!valid"
            @click="save"
            color="primary"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	data() {
		return {
			dialog: false,
			valid: true,
			config: {
				_id: null,
				active: false,
				additionalInformation: null,
				particularities: null,
				preHeatTrain: null,
				preHeatUnit: null,
				series: null,
		  name: null,
		  region: null
			},
			nameRules: [
				v => !!v || 'Es muss ein Name vergeben werden'
			]
		}
	},

	props: {
		series: {
			type: String,
			required: true
	},
	  region: {
		  type: String,
		  required: true
	  }
	},

	computed: {
		...mapGetters({
			getAllSeries: 'series/getAllSeries',
			getSeriesById: 'series/getSeriesById'
		}),
		getSeriesOptions() {
			return this.getAllSeries.map(series => {
				return { '_id': series._id, 'name': series.name }
			})
		},
		getPreHeatUnitOptions() {
			return this.getAllSeries.find(series => series._id === this.config.series).preHeatUnitOptions
		},
		getPreHeatTrainOptions() {
			return this.getAllSeries.find(series => series._id === this.config.series).preHeatTrainOptions
		},
	},

	methods: {
		...mapActions({
			saveConfigurationAction: 'placingConfiguration/saveConfigurationAction'
		}),
		save() {
			this.dialog = false
			this.saveConfigurationAction(this.config)
		},
		cancel() {
			this.dialog = false
		},
		hasPreHeatTrainOptions(id) {
			return this.getAllSeries.find(series => series._id === id).preHeatTrainOptions !== null
		},
		hasPreHeatUnitOptions(id) {
			return this.getAllSeries.find(series => series._id === id).preHeatUnitOptions !== null
		},
	  resetPreHeatUnit() {
		  this.config.preHeatUnit = null
	  },
	  resetPreHeatTrain() {
		  this.config.preHeatTrain = null
	  }
  },
	watch: {
		dialog: function(val) {
			if (val === false) {
				this.config = {
					_id: null,
					active: false,
					additionalInformation: null,
					particularities: null,
					preHeatTrain: null,
					preHeatUnit: null,
					series: null,
					name: null
				}
			} else {
				this.config.series = this.series
		  this.config.region = this.region
			}
		}
	}
}
</script>
