import axios from 'axios'

export const configureAxios = () => {
  axios.defaults.headers.post['Content-Type'] = 'application/json'

  axios.interceptors.request.use(request => {
    const authorization = localStorage.getItem(authorizationHeaderName)
    if (authorization && authorization !== 'undefined') {
      request.headers[authorizationHeaderName] = authorization
    }
    return request
  })

  axios.interceptors.response.use(response => {
    const authorization = response.headers[authorizationHeaderName]
    if (authorization && authorization !== 'undefined') {
      localStorage.setItem(authorizationHeaderName, authorization)
    }
    return response
  })
}
export const authorizationHeaderName = 'authorization'
