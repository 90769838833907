import axios from 'axios'
import { store } from './index'

const state = {
  allRegions: []
}

const getAllRegionsAction = async context => {
  try {
    const response = await axios.get('api/region')
    context.commit('setAllRegionsMutation', response.data)
  } catch (error) {
    console.log('getAllRegionsAction error', error)
  }
}

const saveLocationsAction = async (context, payload) => {
  try {
    const response = await axios.post('api/region/save-location', payload)
    context.commit('updateRegionMutation', response.data)
  } catch (error) {
    console.log('error', error)
  }
}

const saveSeriesAction = async (context, payload) => {
  try {
    const response = await axios.post('api/region/save-series', payload)
    context.commit('updateRegionMutation', response.data)
  } catch (error) {
    console.log('error', error)
  }
}

const addSeriesAction = async (context, payload) => {
  try {
    const response = await axios.post('api/region/add-series', payload)
    context.commit('updateRegionMutation', response.data)
    store.dispatch('series/getAllSeriesAction').then()
  } catch (error) {
    console.log('error', error)
  }
}

const addLocationAction = async (context, payload) => {
  try {
    const response = await axios.post('api/region/add-location', payload)
    context.commit('updateRegionMutation', response.data)
    store.dispatch('location/getAllLocationsAction').then()
  } catch (error) {
    console.log('error', error)
  }
}

const saveRegionAction = async (context, payload) => {
  try {
    const response = await axios.post('api/region/save', payload)
    context.commit('updateRegionMutation', response.data)
  } catch (error) {
    console.log('error', error)
  }
}

const actions = {
  getAllRegionsAction,
  saveLocationsAction,
  saveSeriesAction,
  addSeriesAction,
  addLocationAction,
  saveRegionAction
}

const setAllRegionsMutation = (state, regions) => {
  state.allRegions = regions
}
const updateRegionMutation = (state, region) => {
  state.allRegions = state.allRegions.filter(item => item._id !== region._id)
  state.allRegions.push(region)
}

const mutations = {
  setAllRegionsMutation,
  updateRegionMutation
}

const getters = {
  getAllRegions: state => state.allRegions.sort(sortByName),
  getRegion: state => id => state.allRegions.find(item => item._id === id),
  getSeriesOfRegion: state => id => {
    const region = state.allRegions.find(item => item._id === id)
    if (!region) {
      return null
    }
    const series = [...region.series]
    return series.sort(sortByName)
  },
  getLocationsOfRegion: state => id => {
    const region = state.allRegions.find(item => item._id === id)
    if (!region) {
      return null
    }
    const locations = [...region.locations]
    return locations.sort(sortByName)
  }
}

export const sortByName = (a, b) => a.name.localeCompare(b.name)

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
