import axios from 'axios'

const state = {
  mappings: [],
  updated: {}
}

const getAllMappingsAction = async context => {
  await axios.get('api/mapping')
    .then(response => context.commit('setAllMappingsMutation', response.data))
    .catch(error => console.log('mapping error', error))
}

const saveMappingAction = async (context, payload) => {
  await axios.post('api/mapping/save', payload)
    .then(response => context.commit('updateMappingMutation', response.data))
    .catch(error => console.log('mapping error', error))

}

const saveDefaultAction = async (context, payload) => {
  await axios.post('api/mapping/saveDefault', payload)
    .then(response => context.commit('updateMappingsMutation',
      { mappings: response.data, regionId: payload.regionId, seriesId: payload.seriesId }))
    .catch(error => console.log('mapping error', error))
}

const resetMappingUpdatedAction = () => state.updated = false

const deleteMappingAction = async (context, id) => {
  await axios.delete('api/mapping/delete/' + id)
    .then(response => context.commit('removeMappingMutation', id))
    .catch(error => console.log('error', error))
}

const actions = {
  getAllMappingsAction,
  saveMappingAction,
  resetMappingUpdatedAction,
  deleteMappingAction,
  saveDefaultAction
}

const setAllMappingsMutation = (state, allMappings) => {
  state.mappings = allMappings
}

const updateMappingMutation = (state, mapping) => {
  state.mappings = state.mappings.filter(item => item._id !== mapping._id)
  state.mappings.push(mapping)
  state.updated = true
}

const updateMappingsMutation = (state, payload) => {
  state.mappings = state.mappings.filter(mapping => mapping.region._id !== payload.regionId && mapping.series._id !== payload.seriesId)
  payload.mappings.forEach((item) => state.mappings.push(item))
  state.updated = payload.mappings
}

const removeMappingMutation = (state, id) => {
  state.mappings = state.mappings.filter(item => item._id !== id)
  state.updated = true
}

const mutations = {
  setAllMappingsMutation,
  updateMappingMutation,
  removeMappingMutation,
  updateMappingsMutation
}

const getters = {
  getAllMappings: state => state.mappings,
  getMapping: state => (region, series, location) => state.mappings.find(
    mapping => mapping.series._id === series
      && mapping.region._id === region
      && mapping.location && mapping.location._id === location),
  getDefaultMapping: state => (region, series) => state.mappings.find(mapping => {
    return mapping.series._id === series && mapping.region._id === region && mapping.location === null
  }),
  getMappingUpdated: state => state.updated,
  getLocationsOfLocalMappings: state => (region, series) => state.mappings
    .filter(mapping => mapping.location && mapping.region._id === region && mapping.series._id === series)
    .map(mapping => mapping.location.name)
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
