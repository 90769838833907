<template>
  <div>
    <v-app-bar app>
      <v-toolbar-title
        app
        class="headline text-uppercase"
      >
        <span>ABSTELLUNG (DB REGIO)</span>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          text
          to="/admin/manage-users"
        >
          Benutzerverwaltung
        </v-btn>
        <v-btn
          text
          to="/templates"
        >
          Vorlagen
        </v-btn>
        <v-btn
          text
          to="/region"
        >
          Regionen
        </v-btn>
        <v-btn
          text
          to="/placing-configurations"
        >
          Abstellkonfiguration
        </v-btn>
        <Logout />
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
	import Logout from '../logout'

	export default {
		name: 'Menu',
		components: { Logout }
	}

</script>
