import axios from 'axios'

const state = {
  users: []
}

const getAllUsersAction = async context => {
  try {
    const response = await axios.get('api/user/findAll')
    context.commit('setAllUsersMutation', response.data)
  } catch (error) {
    console.log('error', error)
  }
}

const saveUserAction = async (context, user) => {
  try {
    const response = await axios.post('api/user/save', user)
    context.commit('updateUserMutation', response.data)
  } catch (error) {
    console.log('error', error)
  }
}

const deleteUserAction = async (context, user) => {
  try {
    await axios.delete('api/user/delete/' + user.id)
    context.commit('deleteUserMutation', user.id)
  } catch (error) {
    console.log('error', error)
  }
}

const actions = {
  getAllUsersAction,
  saveUserAction,
  deleteUserAction
}

const setAllUsersMutation = (state, users) => {
  state.users = users
}

const updateUserMutation = (state, user) => {
  state.users = state.users.filter(item => item.id !== user.id)
  state.users.push(user)
}

const deleteUserMutation = (state, userId) => {
  state.users = state.users.filter(item => item.id !== userId)
}

const mutations = {
  setAllUsersMutation,
  updateUserMutation,
  deleteUserMutation
}

const getters = {
  getAllUsers: state => state.users.sort((a, b) => a.username.localeCompare(b.username)),
  getUserRegion: state => id => {
    const user = state.users.find(u => u.id === id)
    return user ? user.region : null
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
