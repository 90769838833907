<template>
  <v-card>
    <v-card-text>
      <v-list-item
        two-line
        v-if="config.series.name === 'DOSTO'"
      >
        <v-list-item-content>
          <v-list-item-title>Abstellmodus</v-list-item-title>
          <v-list-item-subtitle>{{ config.preHeatTrain }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Abstellmodus</v-list-item-title>
          <v-list-item-subtitle>{{ config.preHeatUnit }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Besonderheiten</v-list-item-title>
          <v-list-item-subtitle>{{ config.particularities }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Zusatzinformationen</v-list-item-title>
          <v-list-item-subtitle>{{ config.additionalInformation }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
	data() {
		return {
			dialog: false
		}
	},

	props: {
		config: {
			type: Object,
			required: true
		}
	}
}
</script>
