<template>
  <v-container
    fluid
    class="height100"
  >
    <v-row class="height100">
      <v-col cols="4">
        <v-card class="height100">
          <v-card-title class="title">
            Region
          </v-card-title>
          <v-card-text>
            <div class="mt-3 mb-6">
              <v-dialog
                max-width="500px"
                v-model="dialog"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-3"
                    v-on="on"
                  >
                    Neue Region
                    <v-icon
                      class="right"
                      right
                    >
                      mdi-plus-box
                    </v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Neue Region anlegen</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field
                            label="Name der Region"
                            v-model="newRegion.name"
                            autofocus
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      @click="closeRegionDialog"
                      color="blue darken-1"
                      text
                    >
                      Abbrechen
                    </v-btn>
                    <v-btn
                      @click="saveRegion"
                      color="blue darken-1"
                      text
                    >
                      Speichern
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <div class="mt-3 mb-3">
              <v-select
                :items="allRegions"
                @change="closeOtherForms"
                item-text="name"
                item-value="_id"
                label="Region"
                outlined
                v-model="region"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <!--      location column-->
      <v-col cols="4">
        <v-card class="height100">
          <v-card-title class="title">
            Zugeordnete Abstellanlagen
          </v-card-title>
          <v-card-text>
            <div class="mt-3 mb-6">
              <v-btn
                @click="openLocationsForm"
                class="mr-3"
                v-if="region && !editLocations"
              >
                Anpassen
                <v-icon
                  class="right"
                  right
                >
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                @click="closeOtherForms"
                class="mr-3"
                color="secondary"
                v-if="region && editLocations"
              >
                Abbrechen
              </v-btn>
              <v-btn
                @click="saveLocations"
                class="mr-3"
                v-if="region && editLocations"
              >
                Speichern
              </v-btn>
              <v-dialog
                max-width="500px"
                v-if="!editLocations"
                v-model="dialog2"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-3"
                    v-on="on"
                  >
                    Neu
                    <v-icon
                      class="right"
                      right
                    >
                      mdi-plus-box
                    </v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Neue Abstellanlage anlegen</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field
                            label="Name der Abstellanlage"
                            v-model="newLocation.name"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      @click="closeLocationDialog"
                      color="blue darken-1"
                      text
                    >
                      Abbrechen
                    </v-btn>
                    <v-btn
                      @click="saveLocation"
                      color="blue darken-1"
                      text
                    >
                      Speichern
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <div class="mt-3 mb-3">
              <ViewItemList
                :items="this.getLocationsOfRegion(region)"
                v-if="region && !editLocations"
              />
              <div v-if="region && editLocations">
                <div
                  :key="location._id"
                  v-for="location in this.getAllLocations"
                >
                  <v-checkbox
                    :label="location.name"
                    :value="location"
                    class="pt-0 mt-1"
                    hide-details
                    v-model="selectedLocations"
                  />
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <!--  series column    -->
      <v-col cols="4">
        <v-card class="height100">
          <v-card-title class="title">
            Zugeordnete Baureihen
          </v-card-title>
          <v-card-text>
            <div class="mt-3 mb-6">
              <v-btn
                @click="openSeriesForm"
                class="mr-3"
                v-if="region && !editSeries"
              >
                Anpassen
                <v-icon
                  class="right"
                  right
                >
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                @click="closeSeriesForm"
                class="mr-3"
                color="secondary"
                v-if="region && editSeries"
              >
                Abbrechen
              </v-btn>
              <v-btn
                @click="saveSeries"
                class="mr-3"
                v-if="region && editSeries"
              >
                Speichern
              </v-btn>
              <v-dialog
                max-width="700px"
                v-if="!editSeries"
                v-model="dialog3"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-3"
                    v-on="on"
                  >
                    Neu
                    <v-icon
                      class="right"
                      right
                    >
                      mdi-plus-box
                    </v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Neue Baureihe anlegen</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            label="Name der Baureihe"
                            v-model="newSeries.name"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-combobox
                            :items="[]"
                            hide-selected
                            hint="Bitte Bezeichnung des Abstellzustands eingeben und <kbd>Enter</kbd> drücken"
                            label="Abstelloptionen"
                            multiple
                            persistent-hint
                            small-chips
                            v-model="newSeries.preHeatUnitOptions"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      @click="closeSeriesDialog"
                      color="blue darken-1"
                      text
                    >
                      Abbrechen
                    </v-btn>
                    <v-btn
                      @click="saveNewSeries"
                      color="blue darken-1"
                      text
                    >
                      Speichern
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <div class="mt-3 mb-3">
              <ViewItemList
                :items="this.getSeriesOfRegion(region)"
                v-if="region && !editSeries"
              />
              <div>
                <div
                  :key="series._id"
                  v-for="series in this.getAllSeries"
                >
                  <v-checkbox
                    :label="series.name"
                    :value="series"
                    class="pt-0 mt-1"
                    hide-details
                    v-if="region && editSeries"
                    v-model="selectedSeries"
                  />
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ViewItemList from '../common/view-item-list'

export default {
	name: "Region",
	components: { ViewItemList },
	data() {
		return {
			region: null,
		allRegions: [],
			selectedLocations: [],
		selectedSeries: [],
		editLocations: false,
		editSeries: false,
		editRegion: false,
		newRegion: {
			name: '',
			locations: [],
			series: []
		},
		defaultRegion: {
			name: '',
			locations: [],
			series: []
		},
		newLocation: {
			name: ''
		},
		defaultLocation: {
			name: ''
		},
		newSeries: {
			name: '',
			preHeatUnitOptions: [],
			preHeatTrainOptions: []
		},
		defaultSeries: {
			name: '',
			preHeatUnitOptions: [],
			preHeatTrainOptions: []
		},
		dialog: false,
		dialog2: false,
		dialog3: false
		}
	},
	computed: {
		...mapGetters({
			getAllRegions: 'region/getAllRegions',
			getAllLocations: 'location/getAllLocations',
			getLoginUser: 'login/getLoginUser',
		getRegion: 'region/getRegion',
		getSeriesOfRegion: 'region/getSeriesOfRegion',
		getLocationsOfRegion: 'region/getLocationsOfRegion',
		getAllSeries: 'series/getAllSeries'
		})
	},

	methods: {
		...mapActions({
			getAllRegionsAction: 'region/getAllRegionsAction',
			getAllLocationsAction: 'location/getAllLocationsAction',
		saveLocationAction: 'location/saveLocationAction',
		saveLocationsAction: 'region/saveLocationsAction',
		saveRegionAction: 'region/saveRegionAction',
		getAllSeriesAction: 'series/getAllSeriesAction',
		saveNewSeriesAction: 'series/saveNewSeriesAction',
		saveSeriesAction: 'region/saveSeriesAction',
		addSeriesAction: 'region/addSeriesAction',
		addLocationAction: 'region/addLocationAction'
		}),
	  openLocationsForm() {
		  this.editLocations = true
		  this.selectedLocations = this.getLocationsOfRegion(this.region)
		},
	  openSeriesForm() {
		  this.editSeries = true
		  this.selectedSeries = this.getSeriesOfRegion(this.region)
		},
		saveLocations() {
		this.editLocations = false
			this.saveLocationsAction({ regionId: this.region, locationList: this.selectedLocations })
			this.selectedLocations.sort((a, b) => a.name.localeCompare(b.name))
		},
	  saveSeries() {
		  this.editSeries = false
		  this.saveSeriesAction({ regionId: this.region, seriesList: this.selectedSeries })
		  this.selectedSeries.sort((a, b) => a.name.localeCompare(b.name))
	  },
		setDefaultRegion() {
		if (this.getLoginUser.region) {
			this.region = this.getLoginUser.region._id
		}
		},
	  closeOtherForms() {
		  this.editLocations = false
		  this.editSeries = false
	  },
	  closeLocationsForm() {
		  this.editLocations = false
	  },
	  closeSeriesForm() {
		  this.editSeries = false
	  },
	  saveRegion() {
		  this.dialog = false
		  this.saveRegionAction(this.newRegion)
		  this.newRegion = Object.assign({}, this.defaultRegion)
	  },
	  closeRegionDialog() {
		  this.newRegion = Object.assign({}, this.defaultRegion)
		  this.dialog = false
	  },
	  saveLocation() {
		  this.dialog2 = false
		  this.addLocationAction({ regionId: this.region, location: this.newLocation })
		  this.newLocation = Object.assign({}, this.defaultLocation)
	  },
	  closeLocationDialog() {
		  this.newLocation = Object.assign({}, this.defaultLocation)
		  this.dialog2 = false
	  },
	  saveNewSeries() {
		  this.dialog3 = false
		  this.addSeriesAction({ regionId: this.region, series: this.newSeries })
		  this.newSeries = Object.assign({}, this.defaultSeries)
	  },
	  closeSeriesDialog() {
		  this.newSeries = Object.assign({}, this.defaultSeries)
		  this.dialog3 = false
	  }
  },

	watch: {
		getAllRegions: function(val) {
			if (val) {
				this.allRegions = this.getAllRegions
			}
		}
	},

	mounted() {
		this.getAllRegionsAction()
		this.getAllLocationsAction()
	  this.getAllSeriesAction()
		this.setDefaultRegion()
	}
}
</script>

<style scoped>
  .height100 {
    height: 100%;
  }
</style>
