import axios from 'axios'

const state = {
  allSeries: []
}

const getAllSeriesAction = async context => {
  await axios.get('api/series').then(response => {
    context.commit('setAllSeriesMutation', response.data)
  }).catch(error => console.log('getAllSeriesAction error', error))
}

const saveNewSeriesAction = async (context, payload) => {
  try {
    const response = await axios.post('api/series/save', payload)
    context.commit('setSeriesMutation', response.data)
  } catch (error) {
    console.log('error', error)
  }
}

const actions = {
  getAllSeriesAction,
  saveNewSeriesAction
}

const setAllSeriesMutation = (state, allSeries) => {
  state.allSeries = allSeries
}

const setSeriesMutation = (state, series) => {
  state.allSeries.push(series)
}

const mutations = {
  setAllSeriesMutation,
  setSeriesMutation
}

const getters = {
  getAllSeries: state => state.allSeries,
  getAllSeriesOptions: state => state.allSeries.map(series => {
    return { _id: series._id, name: series.name }
  }),
  getSeriesById: state => id => state.allSeries.find(series => series._id === id)
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
