import axios from 'axios'

const state = {
  systemInfo: {
    app: {
      version: 'keine version',
      profile: 'kein profil'
    }
  }
}

const getSystemInfoAction = async context => {
    await axios.get('api/system-info').then(response => {
      context.commit('setSystemInfoMutation', response)
    }).catch(error => console.log('getSystemInfoAction', error))

}

const actions = {
  getSystemInfoAction
}

const setSystemInfoMutation = (state, response) => {
  state.systemInfo = response.data
}

const mutations = {
  setSystemInfoMutation
}

const getters = {
  getSystemInfo: state => state.systemInfo
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
