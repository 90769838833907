<template>
  <v-btn
    @click="this.logoutAction"
    v-if="this.isLoggedIn"
    text
  >
    <v-icon>mdi-exit-to-app</v-icon>
  </v-btn>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'Logout',

	computed: {
		...mapGetters({
			isLoggedIn: 'login/isLoggedIn'
		})
	},

	methods: {
		...mapActions({
			logoutAction: 'login/logoutAction'
		})
	}
}

</script>
