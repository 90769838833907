<template>
  <v-container
    fluid
    class="height100"
  >
    <v-row class="height100">
      <v-col class="height100">
        <v-card class="height100">
          <v-card-title class="title">
            Benutzerverwaltung
          </v-card-title>
          <v-card-text class="d-flex">
            <v-data-table
              :headers="headers"
              :items="users"
              class="width100"
            >
              <template v-slot:top>
                <v-toolbar
                  color="white"
                  flat
                >
                  <v-spacer />
                  <v-dialog v-model="dialog">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mb-2"
                        color="primary"
                        dark
                        v-on="on"
                      >
                        Neuen Benutzer anlegen
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-form v-model="formValid">
                          <v-container>
                            <v-row>
                              <v-col
                                cols="12"
                                md="4"
                                sm="6"
                              >
                                <v-text-field
                                  :rules="rules.required"
                                  label="Benutzername"
                                  v-model="editedItem.username"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                md="4"
                                sm="6"
                              >
                                <v-text-field
                                  :rules="rules.required"
                                  label="Vorname"
                                  v-model="editedItem.firstname"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                md="4"
                                sm="6"
                              >
                                <v-text-field
                                  :rules="rules.required"
                                  label="Nachname"
                                  v-model="editedItem.lastname"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                md="4"
                                sm="6"
                              >
                                <v-select
                                  :items="regions"
                                  :rules="rules.required"
                                  item-text="name"
                                  item-value="_id"
                                  label="Region"
                                  outlined
                                  v-model="editedItem.region"
                                />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          @click="close"
                          color="blue darken-1"
                          text
                        >
                          Abbrechen
                        </v-btn>
                        <v-btn
                          @click="save"
                          color="blue darken-1"
                          text
                          :disabled="!formValid"
                        >
                          Speichern
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon
                  @click="editItem(item)"
                  class="mr-2"
                  small
                >
                  mdi-square-edit-outline
                </v-icon>
                <v-icon
                  @click="deleteItem(item)"
                  small
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  @click="initialize"
                  color="primary"
                >
                  Reset
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'ManageUsers',

	data: () => ({
		dialog: false,
	  formValid: true,
	  rules: {
		  required: [
			  value => !!value || 'Bitte wählen Sie einen Wert aus!',
		  ],
	  },
		headers: [
			{ text: 'Benutzername', value: 'username' },
			{ text: 'Vorname', value: 'firstname' },
			{ text: 'Nachname', value: 'lastname' },
			{ text: 'Region', value: 'region.name' },
			{ text: 'Actions', value: 'action', sortable: false },
		],
		users: [],
		regions: [],
		editedIndex: -1,
		editedItem: {
			id: '',
			username: '',
			firstname: '',
			lastname: '',
			region: null
		},
		defaultItem: {
			id: '',
			username: '',
			firstname: '',
			lastname: '',
			region: null
		},
	}),

	computed: {
		...mapGetters({
			getAllUsers: 'user/getAllUsers',
			getAllRegions: 'region/getAllRegions'
		}),
		formTitle() {
			return this.editedIndex === -1 ? 'Neuer Benutzer' : 'Benutzer ändern'
		},
	},

	watch: {
		dialog(val) {
			val || this.close()
		},
		getAllUsers(val) {
			if (val) {
				this.initialize()
			}
		},
	},

	methods: {
		...mapActions({
			getAllUsersAction: 'user/getAllUsersAction',
		saveUserAction: 'user/saveUserAction',
		deleteUserAction: 'user/deleteUserAction'
		}),

		initialize() {
			this.users = this.getAllUsers
			this.regions = this.getAllRegions
		},

		editItem(item) {
			this.editedIndex = this.getAllUsers.indexOf(item)
			this.editedItem = Object.assign({}, item)
		const region = this.editedItem.region
		this.editedItem.region = region ? region._id : null
			this.dialog = true
		},

		deleteItem(item) {
			const index = this.users.indexOf(item)
		if (confirm('Wollen Sie den Benutzer wirklich löschen?')) {
			this.users.splice(index, 1)
			console.log('delete', item)
			this.deleteUserAction(item)
		}
		},

		close() {
			this.dialog = false
			setTimeout(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			}, 300)
		},

		save() {
			this.saveUserAction(this.editedItem)
			this.close()
		},
	},

	mounted() {
		this.getAllUsersAction()
		this.initialize()
	}
}
</script>

<style scoped>
  .height100 {
    height: 100%;
  }

  .width100 {
    width: 100%;
  }
</style>
