import axios from 'axios'
import { store } from '.'

const state = {
  allConfigurations: [],
  updated: {},
  formChanged: false
}

const saveConfigurationAction = async (context, payload) => {
  try {
    const response = await axios.post('api/placing-configuration/save', payload)
    context.commit('updateConfigurationMutation', response.data)
  } catch (error) {
    console.log('error', error)
  }
}

const deleteConfigurationAction = async (context, configurationId) => {
  try {
    await axios.delete('api/placing-configuration/delete/' + configurationId)
    await store.dispatch('mapping/getAllMappingsAction')
    context.commit('deleteConfigurationMutation', configurationId)
  } catch (error) {
    console.log('error', error)
  }
}

const getAllConfigurationsAction = async context => {
  try {
    const response = await axios.get('api/placing-configuration')
    context.commit('setAllConfigurationsMutation', response)
  } catch (error) {
    console.log('getAllConfigurationsAction error', error)
  }
}

const getActiveConfigurationAction = async (context, payload) => {
  try {
    const response = await axios.get('api/placing-configuration/findActive/' + payload.seriesId + "/" + payload.locationId)
    context.commit('setRequestedConfigurationMutation', response)
  } catch (error) {
    console.log('error', error)
  }
}

const resetConfigurationUpdatedAction = () => state.updated = false

const setFormChangedAction = context => {
  context.commit('formChangedMutation', true)
}

const resetFormChangedAction = context => {
  context.commit('formChangedMutation', false)
}

const actions = {
  saveConfigurationAction,
  getAllConfigurationsAction,
  getActiveConfigurationAction,
  deleteConfigurationAction,
  resetConfigurationUpdatedAction,
  setFormChangedAction,
  resetFormChangedAction
}

const setAllConfigurationsMutation = (state, response) => {
  state.allConfigurations = response.data
}

const setRequestedConfigurationMutation = (state, response) => {
  state.requestedConfig = response.data
}

const deleteConfigurationMutation = (state, configurationId) => {
  state.allConfigurations = state.allConfigurations.filter(item => item._id !== configurationId)
  state.updated = true
}

const updateConfigurationMutation = (state, configuration) => {
  state.allConfigurations = state.allConfigurations.filter(item => item._id !== configuration._id)
  state.allConfigurations.push(configuration)

  let config = { ...configuration }
  if (configuration.series) {
    config.series = configuration.series._id
  }
  if (config.location) {
    config.location = configuration.location._id
  }
  state.currentForm = config
  state.updated = configuration
}

const formChangedMutation = (state, val) => {
  state.formChanged = val
}

const mutations = {
  setAllConfigurationsMutation,
  setRequestedConfigurationMutation,
  updateConfigurationMutation,
  deleteConfigurationMutation,
  formChangedMutation
}

const getters = {
  getConfig: state => id => state.allConfigurations.find(configuration => configuration._id === id),
  getConfigOptions: state => seriesId => state.allConfigurations.filter(config => config.series._id === seriesId),
  getConfigOptionsRegion: state => (seriesId, regionId) =>
    state.allConfigurations.filter(config => config.series._id === seriesId && config.region._id === regionId),
  getConfigsBySeries: state => seriesId => state.allConfigurations.filter(
    configuration => configuration.series._id === seriesId && !configuration.location),
  getDefaultConfig: state => seriesId => state.allConfigurations.find(configuration => {
    return configuration.active && configuration.series._id === seriesId
  }),
  getConfigurationUpdated: state => state.updated,
  getFormChanged: state => state.formChanged
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
