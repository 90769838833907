<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="1200"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="ma-3"
        >
          Ändern
          <v-icon
            class="right"
            id="dropdown-icon"
            right
          >
            mdi-square-edit-outline
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          Vorlage ändern
        </v-card-title>
        <v-divider />

        <v-card-text>
          <v-alert
            type="warning"
            class="mt-3"
            text
            outlined
          >
            Wenn Sie die Änderungen speichern, wirkt sich das auf alle zugewiesenen Abstellanlagen aus.
          </v-alert>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-row>
              <v-text-field
                v-model="configuration.name"
                label="Name"
                placeholder="neue Konfiguration"
                class="pt-3"
                outlined
                required
                disabled
                :rules="nameRules"
              />
            </v-row>
            <v-row>
              <v-select
                :items="this.getSeriesOptions"
                label="Baureihe"
                outlined
                hide-details
                disabled
                item-text="name"
                item-value="_id"
                v-model="configuration.series"
              />
            </v-row>
            <v-row>
              <v-col
                cols="6"
                v-if="configuration.series && hasPreHeatUnitOptions(configuration.series._id)"
              >
                <v-radio-group
                  label="Abstellen in"
                  class="mt-0"
                  hide-details
                  v-if="configuration.series"
                  v-model="configuration.preHeatUnit"
                >
                  <v-radio
                    :key="option"
                    :label="`${option}`"
                    :value="option"
                    class="mb-0"
                    v-for="option in this.getPreHeatUnitOptions"
                  />
                </v-radio-group>

                <v-btn
                  @click="resetPreHeatUnit"
                  class="mt-3"
                  v-if="configuration.series"
                >
                  Zurücksetzen
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                v-if="configuration.series && hasPreHeatTrainOptions(configuration.series._id)"
              >
                <v-radio-group
                  label="Vorheizen mit Tfz"
                  class="mt-0"
                  hide-details
                  v-model="configuration.preHeatTrain"
                >
                  <v-radio
                    :key="option"
                    :label="`${option}`"
                    :value="option"
                    class="mb-0"
                    v-for="option in this.getPreHeatTrainOptions"
                  />
                </v-radio-group>
                <v-btn
                  @click="resetPreHeatTrain"
                  class="mt-3"
                  v-if="configuration.series"
                >
                  Zurücksetzen
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="pl-0"
              >
                <v-textarea
                  :label="`Besonderheiten`"
                  outlined
                  v-if="configuration.series"
                  v-model="configuration.particularities"
                />
              </v-col>
              <v-col
                class="pr-0"
              >
                <v-textarea
                  :label="`Zusatzinformationen`"
                  outlined
                  v-if="configuration.series"
                  v-model="configuration.additionalInformation"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-spacer />

          <v-btn
            @click="cancel"
            color="secondary"
          >
            Abbrechen
          </v-btn>
          <v-btn
            :disabled="!valid"
            @click="save"
            color="primary"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	data() {
		return {
			dialog: false,
		configuration: {},
			valid: true,
		nameRules:
			[
				v => !!v || 'Es muss ein Name vergeben werden'
			]
		}
	},

	props: {
		config: {
			type: Object,
			required: true
		}
	},

	computed: {
		...mapGetters({
			getAllSeries: 'series/getAllSeries',
			getSeriesById: 'series/getSeriesById'
		}),
		getSeriesOptions() {
			return this.getAllSeries.map(series => {
				return { '_id': series._id, 'name': series.name }
			})
		},
		getPreHeatUnitOptions() {
			return this.getAllSeries.find(series => series._id === this.config.series._id).preHeatUnitOptions
		},
		getPreHeatTrainOptions() {
			return this.getAllSeries.find(series => series._id === this.config.series._id).preHeatTrainOptions
		},
	},

	methods: {
		...mapActions({
			saveConfigurationAction: 'placingConfiguration/saveConfigurationAction'
		}),
		save() {
			this.dialog = false
		const toSave = { ...this.configuration }
			toSave.series = toSave.series._id
			this.saveConfigurationAction(toSave)
		},
		cancel() {
			this.dialog = false
		},
		hasPreHeatTrainOptions(id) {
			return this.getAllSeries.find(series => series._id === id).preHeatTrainOptions !== null
		},
		hasPreHeatUnitOptions(id) {
			return this.getAllSeries.find(series => series._id === id).preHeatUnitOptions !== null
		},
	  resetPreHeatUnit() {
		  this.configuration.preHeatUnit = null
	  },
	  resetPreHeatTrain() {
		  this.configuration.preHeatTrain = null
	  }
  },

	watch: {
		dialog: function(val) {
			if (val === false) {
				this.configuration = {
					_id: null,
					active: false,
					additionalInformation: null,
					particularities: null,
					preHeatTrain: null,
					preHeatUnit: null,
					series: null,
					name: null
				}
	  } else {
		  this.configuration = { ...this.config }
	  }
		}
	},

	mounted() {
		this.configuration = { ...this.config }
	}

}
</script>
