import Vue from 'vue'
import Vuex from 'vuex'
import location from './location'
import login from './login'
import mapping from './mapping'
import placingConfiguration from './placing-configuration'
import region from './region'
import series from './series'
import systemInfo from './system-info'
import user from './user'

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    location,
    login,
    mapping,
    placingConfiguration,
    region,
    series,
    systemInfo,
    user
  }
})
